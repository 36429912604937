

.login-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 24px;
  text-align: left
  max-width: 390px
  margin 0 auto

  .headline-container{
	width: 100%
	text-align: center
  }

  .login-form {
	width: 100%
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin: 24px 0 8px
  }

  h1 {
	font-size: 24px;
  }

  p {
	font-size: 16px;
	color: #666;
  }
}

.text-white {
	color: #fff;
}
